import React from 'react';

import { Creators as ReportActions } from 'store/ducks/report';
import { useDispatch } from 'react-redux';
import { isValid } from 'date-fns';

import SemFiltro from './Types/SemFiltro';
import Documento from './Types/Documento';
import GrupoAcesso from './Types/GrupoAcesso';
import Versionamento from './Types/Versionamento';
import ApenasDepartamento from './Types/ApenasDepartamento';
import DepartamentoAndData from './Types/DepartamentoAndData';
import DepartamentoAndIndexadores from './Types/DepartamentoAndIndexadores';
import ModalFilterApenasData from './Types/ApenasData';
import ModalDataAndOptions from './Types/DataAndOptions';
import DataAndIndexadores from './Types/DataAndIndexadores';

export default function Filter({
  type,
  open,
  form,
  handleClose,
  showSLAFilter,
}) {
  const dispatch = useDispatch();

  const tipo = form.tipo;
  const isExcel = form.isExcel;

  const dateHasError = (date) => {
    try {
      return !isValid(new Date(date));
    } catch (error) {
      return false;
    }
  };

  const handlePrint = (filter) => {
    filter.sendAsMail
      ? dispatch(
        ReportActions.sendAsMailRequest({
          ...filter,
          tipo: tipo,
          isExcel: isExcel,
        })
      )
      : dispatch(
        ReportActions.createRequest({
          ...filter,
          tipo: tipo,
          isExcel: isExcel,
        })
      );

    handleClose();
  };

  switch (type) {
    case 'apenasdepartamento':
      return (
        <ApenasDepartamento
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          key={form.id}
          options={form}
        />
      );
    case 'data_indexadores':
      return (
        <DataAndIndexadores
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          key={form.id}
          options={form}
        />
      );
    case 'departamento_indexadores':
      return (
        <DepartamentoAndIndexadores
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          key={form.id}
          options={form}
        />
      )
    case 'versionamento':
      return (
        <Versionamento
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          key={form.id}
          options={form}
        />
      );
    case 'documento':
      return (
        <Documento
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          key={form.id}
          options={form}
        />
      );
    case 'apenasdata':
      return (
        <ModalFilterApenasData
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          showSLAFilter={showSLAFilter}
          key={form.id}
          options={form}
        />
      );
    case 'auditoria':
      return (
        <DepartamentoAndData
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          showSLAFilter={showSLAFilter}
          key={form.id}
          options={form}
        />
      );
    case 'grupoacesso':
      return (
        <GrupoAcesso
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          key={form.id}
          options={form}
        />
      );
    case 'dataAndoptions':
      return (
        <ModalDataAndOptions
          open={open}
          handlePrint={handlePrint}
          handleClose={handleClose}
          dateHasError={dateHasError}
          key={form.id}
          options={form}
        />
      );

    default:
      return (
        <SemFiltro
          open={open}
          handleClose={handleClose}
          handlePrint={handlePrint}
        />
      );
  }
}
