import React, { useState } from 'react';
import Moment from 'react-moment';

import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BoxRowTable from 'components/BoxRowTable';
import Chronometer from '../../../components/Chronometer';
import Checkbox from '@material-ui/core/Checkbox';

import './styles.css';

export default function ContentRow({
  row, index, onClick, handleCheck, selecionadas, hideFilaEtapaFromSearch, hideEmpresaFromSearch
}) {
  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        cursor: 'pointer',
      },
    },
    box: {
      textAlign: 'left',
    },
  });

  const classes = useRowStyles();

  const { alerta } = row;

  const color = '#fef3c5';
  const colorLight = alerta?.length > 0 ? color : 'white';
  const colorDark = alerta?.length > 0 ? color : '#efefef';
  const limite_de_campos_exibidos = 5;

  return (
    <>
      {row.idEmpresa && (
        <TableRow className={classes.root} style={index % 2 ? { background: colorDark } : { background: colorLight }}>
          <TableCell align="left" style={{ width: '150px' }}>
            <Checkbox
              name="selecionar"
              color="primary"
              checked={selecionadas.includes(row._id)}
              onChange={e => handleCheck(e, row._id)}
            />
          </TableCell>
          {!hideEmpresaFromSearch && (
            <TableCell onClick={onClick} align="left" style={{ width: '150px' }}>
              {row.idEmpresa.razaoSocial}
            </TableCell>
          )}

          <TableCell onClick={onClick} align="left" style={{ width: '150px' }}>
            {row.numeroCNJ}
          </TableCell>

          {row.indexadores
            .filter(indexador => indexador.exibirPesquisaDetalhada)
            .slice(0, limite_de_campos_exibidos)
            .map(indexador => (
              <TableCell
                onClick={onClick}
                align={'left'}
                style={{
                  width: indexador.nome.toUpperCase().includes('NOME') ? '30%' : '150px',
                }}
              >
                {indexador.valor}
              </TableCell>
            ))}
          {!hideFilaEtapaFromSearch && (
            <TableCell onClick={onClick} align="left">
              {
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  {<b>{row.etapa}</b>}
                  {<span>{row.fila}</span>}
                </div>
              }
            </TableCell>
          )}

          {/* <TableCell
            onClick={onClick}
            align="center"
            style={{ width: "150px" }}
          >
            <Chronometer
              onTime={row.dentroDoPrazoSLA}
              duration={row.duration}
            />
          </TableCell>
          <TableCell
            onClick={onClick}
            align="center"
            style={{ width: "100px" }}
          >
            {!row.status ? (
              <div className="badge badge-red">Encerrado</div>
            ) : (
              <div className="badge badge-green">Aberto</div>
            )}
          </TableCell>
          <TableCell
            onClick={onClick}
            align="center"
            style={{ width: "150px" }}
          >
            <span>
              <Moment
                format="DD/MM/YYYY HH:MM"
                date={row.createdAt}
                style={{ fontWeight: "bold" }}
              />
              {row.userAcao && (
                <span className="createdAt-user">
                  Por {row.userAcao ? row.userAcao.nome : ""}
                </span>
              )}
            </span>
          </TableCell> */}
        </TableRow>
      )}
    </>
  );
}
