import React, { useEffect, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import DefaultInput from 'components/DefaultInput';

import {
  AutoCompleteSection,
  TableImages,
  FieldsContainer,
  TextWriter,
} from '../StepTypesStyle';
import RichTextEditor from '../../Utils/Editor';


export default function FieldsStep({ tipoDocumento, onChange, settings }) {
  const [fields, setFields] = useState(settings.fields || []);

  const onEditorStateChange = (content) => {
    onChange(settings, { ...settings, fields, content });
  };

  useEffect(() => {
    console.log("tipoDocumento:", tipoDocumento)
  }, [tipoDocumento])

  useEffect(() => {
    if (fields) {
      onChange(settings, { ...settings, fields });
    }
  }, [fields]);

  return (
    <FieldsContainer>
      <TextWriter>
        <b>Escreva o texto desejado:</b>
        <RichTextEditor
          onEditorStateChange={onEditorStateChange}
          value={settings.content}
        />
      </TextWriter>
      <AutoCompleteSection>
        <Autocomplete
          className="mb-2"
          options={tipoDocumento?.indexadores || []}
          multiple={true}
          getOptionLabel={(option) => option.nome}
          onChange={(e, value) => setFields(value)}
          getOptionSelected={(option, value) => option.id_es === value.id_es}
          defaultValue={tipoDocumento?.indexadores?.filter((item) => {
            return fields.some((i) => i.id_es === item.id_es);
          })}
          renderInput={(params) => (
            <DefaultInput
              {...params}
              name="fields"
              label="Selecione os campos para o formulário"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </AutoCompleteSection>



      <TableImages>
        <table>
          <thead>
            <tr>
              <th>Campo</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {fields && fields.length > 0 ? (
              fields.map((item) => (
                <tr key={item.id_es}>
                  <td>{item.nome}</td>
                  <td>{item.tipo}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">Nenhum campo selecionado</td>
              </tr>
            )}
          </tbody>
        </table>
      </TableImages>
    </FieldsContainer>
  );
}
