import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { put, call, select, delay } from 'redux-saga/effects';

import api from '../../services/api';
import { login, logout, getToken, getTenant, setRetrying, setTenant, AUTH_KEYS } from '../../services/auth';

import { Creators as UsuariosActions } from '../ducks/usuarios';
import { Creators as EmpresaActions } from 'store/ducks/empresa';

/** Auditoria de Dados */
import { createAuditoria } from '../sagas/auditoria';

import { getRegras } from '../sagas/empresas';

import { getPermissoes } from '../sagas/permissoes';

import { getConfiguracoes } from '../sagas/configuracoes';

import { getTranslate } from '../sagas/translate';

import { getRedirectLogin } from '../sagas/redirectlogin';
import { JwtDecode, makeLogoutUrl } from 'pages/Login/context/util';
import { usaKeycloak } from 'utils/utils';

export function getInformations(accessToken) {
  const decodedAccessToken = JwtDecode(accessToken);
  const { preferred_username: username, iss } = decodedAccessToken;
  const xkeyorg = iss.split('/').at(-1);
  setTenant(xkeyorg);
  return { username, xkeyorg };
}

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getPermissoesEmpresas = (state) =>
  state.permissoes.access.empresas;

export const getConfiguracoesUser = (state) =>
  state.configuracoes.configuracoes;

export const getRole = (state) =>
  state.redirectlogin?.data?.role;

export function* loginUser(action) {
  try {
    const { xkeyorg, username, senha, gResponse, useCaptcha } = action.payload;
    yield put(UsuariosActions.setOrganization(xkeyorg));

    const cleanUsername = username.replace(/@.*$/, '');

    const headerParams = { headers: { xkeyorg } };

    const body = { login: cleanUsername, senha, gResponse, useCaptcha };

    const { data } = yield call(api.post, `login`, body, headerParams);

    const { name, token, id } = data

    localStorage.setItem(AUTH_KEYS.TOKEN, "");

    yield getPermissoes(xkeyorg, id, cleanUsername, token);

    yield getConfiguracoes(xkeyorg, token);

    yield getTranslate(xkeyorg, token);

    yield getRedirectLogin(xkeyorg, token);

    const configuracoes = yield select(getConfiguracoesUser);

    const role = yield select(getRole);

    const { expiredPassword, twofactor } = configuracoes;

    let expiredPassDate = new Date(expiredPassword);

    let todayDate = new Date();

    let dateOneFormatted = `${expiredPassDate.getDate() + 1}/${expiredPassDate.getMonth() + 1
      }/${expiredPassDate.getFullYear()}`;

    let dateTwoFormatted = `${todayDate.getDate()}/${todayDate.getMonth() + 1
      }/${todayDate.getFullYear()}`;

    if (dateOneFormatted !== dateTwoFormatted) {
      if (role === "GUEST_VIEWER_CHECKLIST") {
        yield put(push(`/documentos/pesquisa/avancada`));
      } else {
        const twoFactorEnabled = Number(process.env.REACT_APP_ABLE_2FA) === 1;
        if (!twofactor) {
          yield put(push(`/agenda`));

          yield login(data.token);
        } else {
          localStorage.setItem('jwt', data.token);
          yield put(push(`/twofactor`));
        }
      }
    } else {
      yield put(UsuariosActions.expiredPassRequest());
    }

    yield put(UsuariosActions.loginSuccess({ ...data, organization: xkeyorg }));

    // yield timeMaxSessionUser(configuracoes.timeMaxSessionUser);

    const dadosAuditoria = {
      detalhes: {
        acao: 'Login com sucesso',
        rota: '/login',
      },
    };

    /**
     * Dados sendo enviados à API de Auditoria
     */

    yield createAuditoria(dadosAuditoria, token);

    const empresas = yield select(getPermissoesEmpresas);
    const [empresa] = empresas;
    yield put(EmpresaActions.getEmpresaRequest(empresa, token));
  } catch (error) {
    let errorMessage = '';

    if (error.response) {
      switch (error.response.data.errorId) {
        case 'attemptedLogin':
          errorMessage = `Limite de tentativas de login excedido. Tente novamente em ${error.response.data.timeToTry} minuto(s).`;
          break;
        case 'blockedLogin':
          errorMessage =
            'Usuário bloqueado! Entre em contato com seu Administrador.';
          break;
        case 'notFoundUser':
          errorMessage =
            'Usuário não encontrado. Verifique o usuario inserido e tente novamente.';
          break;
        case 'notFoundCompany':
          errorMessage = 'Verifique o nome da empresa e tente novamente.';
          break;
        default:
          errorMessage = `Por favor, verifique seu usuário e senha. Restam ${error.response.data.remainingAttempts} tentativas.`;
          break;
      }
    } else {
      errorMessage = `O sistema está em manutenção, tente novamente mais tarde.`;
    }

    toastr.error('Falha ao fazer login', errorMessage);
    yield put(UsuariosActions.loginError(errorMessage));
  }
}

export function* loginUserKc(action) {
  try {
    const { kctoken } = action.payload;

    const { username } = getInformations(kctoken);

    const xkeyorg = getTenant();
    console.log('xkeyorg', xkeyorg)//teste
    setRetrying(0);

    yield put(UsuariosActions.setOrganization(xkeyorg));

    const headerParams = { headers: { xkeyorg } };

    const body = { login: username, kctoken };

    const { data } = yield call(api.post, `login`, body, headerParams);

    yield login(data.token);

    yield getPermissoes(xkeyorg, data.id);

    yield getConfiguracoes(xkeyorg);

    yield getTranslate(xkeyorg);

    yield getRedirectLogin(xkeyorg);

    const configuracoes = yield select(getConfiguracoesUser);

    const role = yield select(getRole);

    const { expiredPassword } = configuracoes;

    let expiredPassDate = new Date(expiredPassword);

    let todayDate = new Date();

    let dateOneFormatted = `${expiredPassDate.getDate() + 1}/${expiredPassDate.getMonth() + 1
      }/${expiredPassDate.getFullYear()}`;

    let dateTwoFormatted = `${todayDate.getDate()}/${todayDate.getMonth() + 1
      }/${todayDate.getFullYear()}`;

    if (dateOneFormatted !== dateTwoFormatted) {
      if (role === "GUEST_VIEWER_CHECKLIST") {
        yield put(push(`/documentos/pesquisa/avancada`));
      } else {
        const twoFactorEnabled = Number(process.env.REACT_APP_ABLE_2FA) === 1;
        if (!twoFactorEnabled) {
          yield put(push(`/agenda`));
        } else {
          yield put(push(`/twofactor`));
        }
      }
    } else {
      yield put(UsuariosActions.expiredPassRequest());
    }

    yield put(UsuariosActions.loginSuccess({ ...data, organization: xkeyorg }));

    // yield timeMaxSessionUser(configuracoes.timeMaxSessionUser);

    const dadosAuditoria = {
      detalhes: {
        acao: 'Login com sucesso',
        rota: '/login',
      },
    };

    /**
     * Dados sendo enviados à API de Auditoria
     */

    yield createAuditoria(dadosAuditoria);

    const empresas = yield select(getPermissoesEmpresas);
    const [empresa] = empresas;
    yield put(EmpresaActions.getEmpresaRequest(empresa));
  } catch (error) {
    let errorMessage = '';

    if (error.response) {
      switch (error.response.data.errorId) {
        case 'attemptedLogin':
          errorMessage = `Limite de tentativas de login excedido. Tente novamente em ${error.response.data.timeToTry} minuto(s).`;
          break;
        case 'blockedLogin':
          errorMessage =
            'Usuário bloqueado! Entre em contato com seu Administrador.';
          break;
        case 'notFoundUser':
          errorMessage =
            'Usuário não encontrado. Verifique o usuario inserido e tente novamente.';
          break;
        case 'notFoundCompany':
          errorMessage = 'Verifique o nome da empresa e tente novamente.';
          break;
        default:
          errorMessage = `Por favor, verifique seu usuário e senha. Restam ${error.response.data.remainingAttempts} tentativas.`;
          break;
      }
    } else {
      errorMessage = `O sistema está em manutenção, tente novamente mais tarde.`;
    }

    toastr.error('Falha ao fazer login', errorMessage);
    yield put(UsuariosActions.loginError(errorMessage));
  }
}

export function* logoutUser() {
  try {
    logout();
    if (usaKeycloak()) {
      const logoutUrl = makeLogoutUrl();
      if (!logoutUrl) return;
      window.location.href = logoutUrl;
    } else {
      window.location.href = '/'
    }
  } catch (error) {
    alert('Erro ao deslogar');
  }
}

export function* goToApp({ payload }) {
  window.location.replace(
    `https://${payload}.newspace.com.br/auth/${getToken()}`
  );
}

export function* loginWithToken({ payload }) {
  try {
    const headerParams = { headers: { Authorization: payload } };

    const { data } = yield call(
      api.post,
      `auth`,
      { JWT: payload },
      headerParams
    );

    yield login(data.token);

    yield put(UsuariosActions.loginSuccess({ ...data }));

    yield getPermissoes();

    yield getRegras(data.empresa);

    yield getConfiguracoes();

    const configuracoes = yield select(getConfiguracoesUser);

    const { expiredPassword } = configuracoes;

    let expiredPassDate = new Date(expiredPassword);

    let todayDate = new Date();

    let dateOneFormatted = `${expiredPassDate.getDate() + 1}/${expiredPassDate.getMonth() + 1
      }/${expiredPassDate.getFullYear()}`;

    let dateTwoFormatted = `${todayDate.getDate()}/${todayDate.getMonth() + 1
      }/${todayDate.getFullYear()}`;

    if (dateOneFormatted !== dateTwoFormatted) {
      yield put(push(`/inicio`));
    } else {
      yield put(UsuariosActions.expiredPassRequest());
    }

    // yield timeMaxSessionUser(configuracoes.timeMaxSessionUser);

    const empresas = yield select(getPermissoesEmpresas);
    const [empresa] = empresas;
    yield put(EmpresaActions.getEmpresaRequest(empresa));
  } catch (error) {
    yield put(
      UsuariosActions.loginWithTokenError(
        'Não foi possível realizar a autenticação'
      )
    );

    console.error(error);
  }
}

export function* listUsers({ payload }) {
  const organization = yield select(getOrganization);

  const { empresas, page, limit, sort } = payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort },
    };

    const response = yield call(api.post, `users`, empresas, headerParams);

    const data = {
      list: response.data.docs,
      total: response.data.total,
    };

    yield put(UsuariosActions.listSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar os usuários.');
  }
}

export function* editUser(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.put,

      `user/${action.payload._id}`,

      action.payload,

      headerParams
    );

    yield put(UsuariosActions.editSuccess());

    toastr.success('', 'Usuário editado com sucesso.');

    yield put(push('/administracao/usuarios'));
  } catch (error) {
    console.error(error);
    toastr.error('Ops', 'Não foi possível editar o usuário.');
  }
}

export function* changePassword(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.put,
      `user/${action.payload.id}`,
      action.payload,
      headerParams
    );

    yield put(UsuariosActions.changePasswordSuccess());

    toastr.success('', 'Senha alterada com sucesso.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível alterar a senha.');
  }
}

export function* timeMaxSessionUser(timeMax) {
  yield delay(timeMax);

  yield logoutUser();
}

export function* forgotPassword(action) {
  try {
    yield call(api.post, `user/email`, action.payload);

    toastr.success('', 'Senha temporária enviada ao seu e-mail.');
  } catch (error) {
    toastr.error(
      'Ops',
      'Não foi possível enviar a senha temporária ao login informado.'
    );
  }
}

export function* listUsersByDepartamento(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `users/departamento/${action.payload}`,
      headerParams
    );

    yield put(UsuariosActions.listSuccessByDepto(data));
  } catch (error) {
    toastr.error(
      'Ops',
      'Não foi possível listar os usuários por departamento.'
    );
  }
}

export function* listUsersByAccessGroup(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `users/accessgroup/${action.payload}`,
      headerParams
    );

    yield put(UsuariosActions.listSuccessRequestUsersAccessGroup(data));
  } catch (error) {
    toastr.error(
      'Ops',
      'Não foi possível listar os usuários por departamento.'
    );
  }
}

export function* editEmpresaPermissionUser(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.post, '/user/novaempresa', action.payload, headerParams);
  } catch (error) {
    toastr.error(
      'Ops',
      'Não foi possível inserir a empresa no usuário logado.'
    );
  }
}

export function* validateToken(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/user/validatetoken/${action.payload}`,
      headerParams
    );

    yield put(UsuariosActions.validadeTokenSuccess(data));
  } catch (error) {
    console.error('Token inválido');
  }
}

export function* changePasswordByLogin(action) {
  const { usuario, org } = action?.payload;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: org },
    };

    yield call(api.post, `/user/changepassword`, action.payload, headerParams);

    yield put(UsuariosActions.changePasswordByLoginSuccess());

    toastr.success('', 'Senha alterada com sucesso.');

    yield put(push('/'));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível alterar a senha.');
  }
}

export function* changePasswordByNewPass(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const newpassword = yield call(
      api.post,
      `/user/newpassword`,
      action.payload,
      headerParams
    );

    if (newpassword.status === 203) {
      toastr.error(
        'Ops',
        'A senha atual informada não corresponde com a senha cadastrada. Favor verificar.'
      );

      return;
    }

    yield put(UsuariosActions.changePasswordByLoginSuccess());

    toastr.success('', 'Senha alterada com sucesso.');

    yield put(push('/'));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível alterar a senha.');
  }
}

export function* searchAnalystsByName({ payload }) {
  const organization = yield select(getOrganization);


  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: {
        q: payload
      }
    };

    const { data } = yield call(api.get, 'users/linked', headerParams);

    if (data?.error) {
      toastr.error('Ops', 'Não foi possível listar os analistas.');
      return
    }

    yield put(UsuariosActions.searchByNameSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar os analistas.');
  } finally {
    yield put(UsuariosActions.toggleAnylystsLoading(false))
  }
}

export function* twoFactorValidate(action) {
  const organization = yield select(getOrganization);

  try {

    const headerParams = {
      headers: { Authorization: action.payload.jwt, xkeyorg: organization },
    };


    const { data } = yield call(
      api.post,
      `/user/two-factor`,
      action.payload,
      headerParams
    )

    if (data?.error || !data?.validation) {
      return;
    }

    yield login(action.payload.jwt);

    yield put(UsuariosActions.twoFactorSuccess());

    yield put(push('/agenda'));
  } catch (error) {
    console.error(error);
    // toastr.error('Ops', 'Erro ao validar o código de dupla autenticação.');//TODO: verificar isso aqui posteriormente
  }
}
