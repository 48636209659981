import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { DialogContent } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';

import { Creators as TiposDocumentoActions } from 'store/ducks/tiposdocumento';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '@material-ui/core/Dialog';

import useForm from 'react-hook-form';

import { LabelSwapper } from '../../../../../components/LabelSwapper';
import { Button, GroupButton, DialogTitle } from './style';
import { MailFileOptions } from '../MailFileOptions';

const INITIAL_FORM = {
  indexadores: [],
  tipodoc: '',
  dataInicial: '',
  dataFinal: '',
  attachmentType: null,
};

function ModalFilterDataAndIndexadores({
  open,
  handleClose,
  handlePrint,
  dateHasError,
  options,
}) {
  const dispatch = useDispatch();

  const { register } = useForm();
  const [form, setForm] = useState(INITIAL_FORM);

  const handleChangeForm = (name, value, index) => {
    console.log("handleChangeForm ", value);
    if (name === 'indexador') {
      const newIndexadores = [...form.indexadores];
      newIndexadores[index] = value;
      setForm({ ...form, indexadores: newIndexadores });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const addIndexador = () => {
    setForm({ ...form, indexadores: [...form.indexadores, ''] });
  };

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    dispatch(TiposDocumentoActions.listByEmpresaRequest(empresas));
  }, [dispatch]);


  const { listByEmpresas: tipoDocList, loading: loadingTipoDoc } = useSelector(
    (state) => state.tipoDocumentos
  );

  const atachmentHasValue = options.sendAsMail ? form.attachmentType : true;

  const disableSendButtonWhen =
    dateHasError(form.dataInicial) ||
    dateHasError(form.dataFinal) ||
    !form.tipodoc ||
    !atachmentHasValue;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ minWidth: '600px' }}
      ></DialogTitle>
      <DialogContent>
        <Row>
          <Col xm={12} sm={12} md={6}>
            <TextField
              required
              type="date"
              name="dataInicial"
              label="Período Inicial"
              value={form.dataInicial}
              onChange={(e) => handleChangeForm('dataInicial', e.target.value)}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col xm={12} sm={12} md={6}>
            <TextField
              required
              type="date"
              name="dataFinal"
              label="Período Final"
              onChange={(e) => handleChangeForm('dataFinal', e.target.value)}
              value={form.dataFinal}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            {!loadingTipoDoc ? (
              <Autocomplete
                required
                size="small"
                onChange={(event, newValue) => {
                  handleChangeForm('tipodoc', newValue);
                }}
                options={tipoDocList}
                getOptionLabel={(option) => option.nome}
                noOptionsText="Selecione um tipo documento"
                getOptionSelected={(option, value) => option._id === value._id}
                clearOnBlur={false}
                name="tipodoc"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register}
                    name="modelType"
                    label="Tipo de documento"
                    variant="outlined"
                  />
                )}
              />
            ) : (
              <Skeleton width="100%" height={50} />
            )}
          </Col>
        </Row>
        <Row>
          <Col className='mt-4'>
            <h6>Campos adicionais:</h6>
          </Col>
        </Row>
        {form.indexadores.map((indexador, index) => (
          <Row key={index} className="mt-3">
            <Col md={12}>
              <Autocomplete
                required
                size="small"
                options={form.tipodoc?.indexadores || []}
                onChange={(event, newValue) => {
                  handleChangeForm('indexador', newValue, index);
                }}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register}
                    name={`indexador-${index}`}
                    label={`Indexador ${index + 1}`}
                    variant="outlined"
                  />
                )}
              />
            </Col>
          </Row>
        ))}
        <Row>
          <Col md={12}>
            <Button
              className="mt-3"
              color="primary"
              variant="contained"
              size="small"
              onClick={addIndexador}
            >
              Adicionar indexador
            </Button>
          </Col>
        </Row>
        {options.sendAsMail && (
          <Row className="mt-3">
            <Col md={12}>
              <MailFileOptions
                canPrint={options.canPrint}
                canSheel={options.canSheel}
                onChange={(option) =>
                  handleChangeForm('attachmentType', option?.value)
                }
              />
            </Col>
          </Row>
        )}
      </DialogContent>
      <GroupButton className="mt-2">
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableSendButtonWhen}
          onClick={() => {
            handlePrint({
              dataInicial: form.dataInicial,
              dataFinal: form.dataFinal,
              tipoDoAnexo: form.attachmentType,
              sendAsMail: options.sendAsMail,
              indexadores: form.indexadores.map((indexador) => { return { index: indexador?.index, nome: indexador?.nome } }),
            });
          }}
        >
          {options.sendAsMail ? 'Enviar por e-mail' : 'Imprimir'}
        </Button>
      </GroupButton>
    </Dialog>
  );
}

export default ModalFilterDataAndIndexadores;
