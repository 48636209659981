import React from 'react';

import InputMask from 'react-input-mask';
import DOMPurify from 'dompurify';

import { ConectorOnboardingFields, Container, FormContainer, FormField } from './styled';
import { ImageTextContainer } from '../image/styled';


const FieldsStep = ({ settings, onFieldsChange }) => {
  function handleFieldChange(e) {
    let value = e.target.value;
    value = value.replace(/[_]/g, '');

    onFieldsChange({ target: { name: e.target.name, value } });
  }


  function displayIndexadorWithDependencia(indexador) {
    const idxs = [...settings.fields];

    if (!indexador.dependenciaIndexador) {
      return "block";
    }

    const valorIdxDependencia = idxs.find(idx => indexador.dependenciaIndexador.index === idx.index).valor;


    //checar se o valor do indexador.dependenciaIndexador.index é igual ao valor do indexador.dependenciaIndexador.condicoes.valor

    const matchedCondition = indexador.dependenciaIndexador?.condicoes.find(condicao => condicao.valor === valorIdxDependencia);


    return !!matchedCondition === false ? "none" : "block";
  }


  function changeInputType(field) {
    if (field.tipo === 'numero') {
      return 'number';
    }

    return 'text';
  }

  return (
    <Container>
      <h4>{settings.text}</h4>
      <FormContainer>
        <form>

          {settings?.content && settings.content.length > 0 ? <ImageTextContainer dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(settings?.content) }} /> : null}

          {settings.fields.map((field, idx) => (
            <FormField key={`field${idx}`} style={{ display: displayIndexadorWithDependencia(field), width: '100%' }}>
              <div>
                <label htmlFor={field.nome}>{field.nome}:</label>
                {field.tipo === 'combo' && (
                  <select style={{ width: "100%" }} name={field.nome} id={field.nome} onChange={handleFieldChange} defaultValue={field.valor}>
                    <option value="">Selecione</option>
                    {field.opcoes.map((opcao) => (
                      <option value={opcao}>{opcao}</option>
                    ))}
                  </select>
                )}
                {

                }
                {field.tipo === 'caracter' && field.mascara && (
                  <InputMask mask={field.mascara} placeholder={''} key={field.nome} name={field.nome}
                    autoComplete="new-password"
                    defaultValue={field.valor}
                    onChange={handleFieldChange}>
                    {(inputProps) => <input
                      {...inputProps}
                      type={changeInputType(field.tipo)}
                    />}
                  </InputMask>

                )}

                {field.tipo === 'data' && (
                  <InputMask mask="99/99/9999" placeholder="dd/mm/aaaa" key={field.nome} name={field.nome}
                    autoComplete="new-password"
                    defaultValue={field.valor}
                    onChange={handleFieldChange}>
                    {(inputProps) => <input
                      {...inputProps}

                      type={changeInputType(field.tipo)}

                    />}
                  </InputMask>

                )}

                {field.tipo === 'cpf' && (
                  <>
                    {field.conectorOnboarding ? (
                      <>
                        <ConectorOnboardingFields>{field.valor}</ConectorOnboardingFields>
                        <input type="hidden" value={field.valor} name={field.nome} id={field.nome} />
                      </>
                    ) :
                      (
                        <InputMask mask="999.999.999-99" placeholder="999.999.999-99" key={field.nome} name={field.nome}
                          autoComplete="new-password"
                          defaultValue={field.valor}
                          disabled={field.conectorOnboarding}
                          onChange={handleFieldChange}>
                          {(inputProps) => <input
                            {...inputProps}
                            type={changeInputType(field.tipo)}

                          />}
                        </InputMask>)}
                  </>
                )}

                {field.tipo === 'caracter' && !field.mascara && (
                  <input
                    key={field.nome}
                    type={changeInputType(field.tipo)}
                    name={field.nome}
                    defaultValue={field.valor}
                    onChange={handleFieldChange}
                    autoComplete="new-password"
                  />
                )}
              </div>
            </FormField>
          ))}
        </form>
      </FormContainer>
    </Container>
  );
};

export default FieldsStep;
