import React, { useEffect, useState } from 'react';

import { registerLocale } from 'react-datepicker';

import br from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';

import Grid from '@material-ui/core/Grid';
import ButtonMui from '@material-ui/core/Button';
import BackButton from '../../components/BackButton';

import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from "@material-ui/core/TextField";
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useForm from 'react-hook-form';

import './style.css';

import { Creators as ConfiguracaoActions } from '../../store/ducks/configuracoes';
import { Creators as GrupoEmailActions } from '../../store/ducks/grupoemail';

import { useDispatch, useSelector } from 'react-redux';
import Content from 'components/Content';
import { remove } from 'lodash';

export default function Configuracoes() {
  registerLocale('pt-br', br);
  const dispatch = useDispatch();

  const usuario = useSelector(state => state.usuarios.auth);

  const configuracoes = useSelector(state => state.configuracoes.configuracoes);
  const {
    loading: grupoemailLoading,
    list: grupoemailList
  } = useSelector((state) => state.grupoemail);


  const [validadeSenha, setValidadeSenha] = useState(new Date());
  const [grupoEmail, setGrupoEmail] = useState("");

  useEffect(() => {
    dispatch(
      GrupoEmailActions.listRequest({
        page: 1,
        limit: 10000,
      })
    )

    dispatch(ConfiguracaoActions.getRequest());
  }, [dispatch]);

  const { register, handleSubmit } = useForm();

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleGrupoEmail = (grupoemail) => {
    if (!grupoemail) setGrupoEmail("");
    setGrupoEmail(grupoemail._id);
  }

  const onSubmit = async values => {
    if (values.logo && values.logo[0]) {
      const logoBase64 = await toBase64(values.logo[0])
      values.logo = logoBase64;
    } else {
      delete values.logo;
    }

    if (!grupoEmail) {
      delete values.sendNotificationWhatsappEmailGroup;
    } else {
      values.sendNotificationWhatsappEmailGroup = grupoEmail;
    }

    dispatch(ConfiguracaoActions.editRequest(values));
  };

  return (
    <Content>
      <Row>
        <Col md="12" className="painel">
          <div className="painel-content">
            <div className="painel-title text-left">Configurações do sistema</div>
            <div className="painel-body">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="row">
                  <Col>
                    <Form.Label>Logo</Form.Label>
                    <Form.Control type="file" name="logo" ref={register} />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Quantidade de tentativas para bloqueio</Form.Label>
                    <Form.Control
                      type="number"
                      name="blockAfterAttempts"
                      ref={register}
                      defaultValue={configuracoes.blockAfterAttempts}
                    />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Minutos de espera até a próxima tentativa</Form.Label>
                    <Form.Control
                      type="number"
                      name="minutesToEnableLogin"
                      ref={register}
                      defaultValue={configuracoes.minutesToEnableLogin}
                    />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Quantidade de tentativas para inativar por determinado tempo o login</Form.Label>
                    <Form.Control
                      type="number"
                      name="attemptsToWaitLogin"
                      ref={register}
                      defaultValue={configuracoes.attemptsToWaitLogin}
                    />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label className="mb-0">Ampliar tela da análise</Form.Label>
                  </Col>
                  <span className="d-inline-block mt-1">
                    <Form.Check
                      type="checkbox"
                      name="showExtendedAnalysis"
                      ref={register}
                      defaultChecked={configuracoes.showExtendedAnalysis}
                    />
                  </span>
                </Row>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label className="mb-0">Esconder fila/etapa na busca</Form.Label>
                  </Col>
                  <span className="d-inline-block mt-1">
                    <Form.Check
                      type="checkbox"
                      name="hideFilaEtapaFromSearch"
                      ref={register}
                      defaultChecked={configuracoes.hideFilaEtapaFromSearch}
                    />
                  </span>
                </Row>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label className="mb-0">Esconder empresa na busca</Form.Label>
                  </Col>
                  <span className="d-inline-block mt-1">
                    <Form.Check
                      type="checkbox"
                      name="hideEmpresaFromSearch"
                      ref={register}
                      defaultChecked={configuracoes.hideEmpresaFromSearch}
                    />
                  </span>
                </Row>
                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label className="mb-0"> Habilitar Configuração De Marcador de Status Pendente e Escalonamento</Form.Label>
                  </Col>
                  <span className="d-inline-block mt-1">
                    <Form.Check
                      type="checkbox"
                      name="changeStatusPending"
                      ref={register}
                      defaultChecked={configuracoes.changeStatusPending ?? true}
                    />
                  </span>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Tempo Máximo de Sessão</Form.Label>
                    <Form.Control
                      type="number"
                      name="timeMaxSessionUser"
                      ref={register}
                      defaultValue={configuracoes.timeMaxSessionUser}
                    />
                  </Col>
                </Row>
                <Row className="row">
                  <Col>
                    <Form.Label>Força da senha</Form.Label>

                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip-disabled">Precisa conter ao menos uma letra.</Tooltip>}
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Muito Fraca"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])"
                          name="strengthPassword"
                          defaultChecked={configuracoes.strengthPassword === '^(?=.*[a-z])'}
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra e uma letra maiúscula.
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Fraca"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])"
                          name="strengthPassword"
                          defaultChecked={configuracoes.strengthPassword === '^(?=.*[a-z])(?=.*[A-Z])'}
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra, uma letra Maiúscula e um número
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Média"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])"
                          name="strengthPassword"
                          defaultChecked={configuracoes.strengthPassword === '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])'}
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra, uma letra Maiúscula, um número e um caracter especial
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Forte"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])"
                          name="strengthPassword"
                          alt=""
                          defaultChecked={
                            configuracoes.strengthPassword === `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])`
                          }
                        />
                      </span>
                    </OverlayTrigger>

                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Precisa conter ao menos uma letra, uma letra Maiúscula, um número, um caracter especial e
                          mínimo de 8 caracteres
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Form.Check
                          inline
                          label="Muito forte"
                          type="radio"
                          ref={register}
                          defaultValue="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"
                          name="strengthPassword"
                          defaultChecked={
                            configuracoes.strengthPassword ===
                            `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})`
                          }
                        />
                      </span>
                    </OverlayTrigger>
                  </Col>
                </Row>

                <Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Label className="mb-0"> Habilitar recebimento de notificação ao receber arquivo via whatsapp</Form.Label>
                  </Col>
                  <span className="d-inline-block mt-1">
                    <Form.Check
                      type="checkbox"
                      name="sendNotificationWhatsappFiles"
                      ref={register}
                      defaultChecked={configuracoes.sendNotificationWhatsappFiles ?? true}
                    />
                  </span>
                </Row>

                <Row className="row">
                  <Col>
                    <Form.Label>Código padrão para acesso ao onboarding</Form.Label>
                    <Form.Control
                      type="number"
                      name="codigoAdmOnboarding"
                      ref={register}
                      defaultValue={configuracoes.codigoAdmOnboarding}
                    />
                  </Col>
                </Row>

                <Row className="row">
                  <Col md={6}>
                    <Form.Label>Receber notificação (telefone) ao receber arquivo por whatsapp</Form.Label>
                    <Form.Control
                      type="text"
                      name="sendPhoneNotificationWhatsappFiles"
                      ref={register}
                      defaultValue={configuracoes.sendPhoneNotificationWhatsappFiles}
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Label>Receber notificação (email) ao receber arquivo por whatsapp</Form.Label>
                    {grupoemailLoading ? (
                      <Skeleton />
                    ) : (
                      <Autocomplete
                        ref={register}
                        options={grupoemailList}
                        onChange={(event, newValue) => handleGrupoEmail(newValue)}
                        getOptionLabel={(option) => option.nome}
                        getOptionSelected={(option, value) => option?._id === value?._id}
                        value={grupoemailList?.find((g) => g._id === configuracoes.sendNotificationWhatsappEmailGroup)}
                        name="sendNotificationWhatsappEmailGroup"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Grupo de email"
                            size="small"
                            variant="outlined"
                            className="mb-4"
                          />
                        )}
                      />)}

                  </Col>
                </Row>



                {/* <Row>
                      <Col>
                        <Button type="submit">Salvar</Button>
                      </Col>
                    </Row> */}

                <Grid container spacing={1}>
                  <Grid item>
                    <ButtonMui variant="contained" color="primary" type="submit">
                      Enviar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <ButtonMui variant="contained" color="secondary" type="reset">
                      Limpar
                    </ButtonMui>
                  </Grid>
                  <Grid item>
                    <BackButton variant="contained" color="secondary">
                      Voltar
                    </BackButton>
                  </Grid>
                </Grid>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
}
